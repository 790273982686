"use client";

import { Input, Checkbox, Button } from "@60hz-io/shds";
import { Controller, useFormContext } from "react-hook-form";
import Image from "next/image";
import Link from "next/link";
import { PAGE_ROUTES } from "@/constants/pageRoute.constant";
import loginLogo from "../../../../assets/images/login-logo.svg";
import { SignInFormFieldValues } from "./SignInForm.type";

interface SignInFormProps {
  errorMessage?: string;
}

export function SignInForm({ errorMessage }: SignInFormProps) {
  const { control } = useFormContext<SignInFormFieldValues>();

  return (
    <div className="h-[640px] px-[40px] py-[60px] flex flex-col justify-center">
      <div className="flex flex-col items-center">
        <Image src={loginLogo} width={262} height={36} alt="login logo" className="h-[36px] w-[262px]" />
      </div>
      <Controller
        control={control}
        name="email"
        rules={{
          required: true,
        }}
        render={({ field: { value, ...field }, fieldState: { error } }) => (
          <Input
            className="mt-[60px] w-full"
            size="big"
            isError={!!error}
            placeholder="이메일 입력"
            value={value}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="password"
        rules={{
          required: true,
        }}
        render={({ field: { value, ...field }, fieldState: { error } }) => (
          <Input
            className="mt-[16px] w-full"
            size="big"
            type="password"
            isError={!!error}
            placeholder="비밀번호 입력"
            value={value}
            hasPasswordVisibleIcon
            {...field}
          />
        )}
      />

      <div className="mt-[16px] flex items-center justify-between">
        <Controller
          control={control}
          name="shouldPreserveEmail"
          render={({ field: { value, onChange } }) => (
            <Checkbox
              id="shouldPreserveEmail"
              labelText="이메일 저장"
              checked={!!value}
              onChange={(e) => {
                onChange(e.target.checked);
              }}
            />
          )}
        />
        <Link className="c14-semibold text-context-link" href={PAGE_ROUTES.resetPasswordVerify}>
          비밀번호 재설정
        </Link>
      </div>
      {errorMessage && <div className="c14-medium text-context-error mt-[16px] text-center">{errorMessage}</div>}
      <Button size="big" className="mt-[16px] w-full" type="submit" appearance="primary">
        로그인
      </Button>
    </div>
  );
}
