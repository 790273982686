"use client";

import { FormProvider, useForm } from "react-hook-form";
import { useRouter, useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { usePostV1UserLogin } from "@/api/client/user/user";
import { SignInFormFieldValues } from "@/components/organisms/SignInForm/SignInForm.type";
import { SignInForm } from "@/components/organisms/SignInForm/SignInForm";
import { StatusCode, STATUS_CODE_ALERT_MESSAGE_MAP } from "@/api/statusCode.constant";
import { PAGE_ROUTES } from "@/constants/pageRoute.constant";
import { PRESERVE_EMAIL_LOCAL_STORAGE_KEY } from "./SignInContainer.constant";

export function SignInContainer() {
  const methods = useForm<SignInFormFieldValues>({
    defaultValues: {
      email: "",
      password: "",
      shouldPreserveEmail: false,
    },
  });
  const { email, password } = methods.watch();

  const router = useRouter();
  const searchParams = useSearchParams();

  const {
    mutateAsync: signIn,
    reset: resetSignInError,
    data: signInResult,
  } = usePostV1UserLogin({
    mutation: {
      onSuccess: (result, { data }) => {
        if (result.data.status.code === StatusCode.Success) {
          if (methods.getValues("shouldPreserveEmail")) {
            localStorage.setItem(PRESERVE_EMAIL_LOCAL_STORAGE_KEY, data.email);
          } else {
            localStorage.removeItem(PRESERVE_EMAIL_LOCAL_STORAGE_KEY);
          }
          router.push(searchParams.get("returnUrl") || PAGE_ROUTES.dashboard.contractManagement);
        }
      },
    },
  });

  useEffect(() => {
    const preservedEmail = localStorage.getItem(PRESERVE_EMAIL_LOCAL_STORAGE_KEY);
    if (preservedEmail) {
      methods.setValue("email", preservedEmail);
      methods.setValue("shouldPreserveEmail", true);
    }
  }, [methods]);

  useEffect(() => {
    resetSignInError();
  }, [email, password, resetSignInError]);

  return (
    <div>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((fieldValues) => {
            signIn({
              data: {
                email: fieldValues.email,
                password: fieldValues.password,
              },
            });
          })}
        >
          <SignInForm
            errorMessage={
              signInResult?.data.status.code
                ? STATUS_CODE_ALERT_MESSAGE_MAP[signInResult.data.status.code]?.title
                : undefined
            }
          />
        </form>
      </FormProvider>
    </div>
  );
}
